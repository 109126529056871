import { Navigate, Outlet } from "react-router-dom";
import Topbar from "./Topbar";
import "../style/PersistLogin2.css";
import { useDispatch, useSelector } from "react-redux";
import { send_ordine } from "../store/actions/ordini";


const PersistLogin2 = (props) => {
  const dispatch = useDispatch();
  const carrello = useSelector((state) => state.ordini.carrello);
  const totale = useSelector((state) => state.ordini.tot_prezzo);
  const storico = useSelector((state) => state.ordini.storico);


  const iscarrello = useSelector((state) => state.ordini.iscarrello);

  const handleSend = async () => {
    await dispatch(send_ordine(carrello, totale));
  };
  return (
    <div className="container-prodotti">
      <div className="top">
        <Topbar iscarrello={iscarrello} onSend={handleSend}  storico={storico}/>
      </div>
      {props.nuovo ? <Outlet /> : <Navigate to="/apritavolo" />}
      
      </div>
  );
};

export default PersistLogin2;
