import React from "react";
import "../style/RadioButton.css";

const RadioButton = ({ prezzo, id, value, onChange, checked, text }) => {
  return (
    <label htmlFor={id} className={`radio-label ${checked? "active-choise" : ""}`}>
      <input
        className="radio-input"
        type="radio"
        prezzo={prezzo}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <span className="custom-radio" />
      <div className="radio-text">
        {text}({prezzo} €)
      </div>
    </label>
  );
};
export default RadioButton;
