import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { node } from "../Axios";
import Navbar from "../components/Navbar";
import Spinner from "../components/Spinner";
import Table from "../components/Table";
import { loading_login, LOGIN_CHECK, logout } from "../store/actions/login";
import { get_storico_ordini, set_iscarrello } from "../store/actions/ordini";
import "../style/Storico.css";
import { getSession } from "../utils/session";

const Storico = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ordini.loading);

 const storico=useSelector((state) => state.ordini.storico_ordini);

  useEffect(() => {
    async function fetchstorico() {
      dispatch(get_storico_ordini());
      
    }
    fetchstorico();
  }, []);
  return <div className="container">
    {loading ? <Spinner/> :
      storico.length===0 ?  "non sono presenti ordini inviati" : <Table storico={storico}/>
    }
  </div>;
};

export default Storico;
