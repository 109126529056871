import React from "react";
import "../style/Table.css";

const Table = ({ storico }) => {
  console.log("Storico", storico[0].carrellos);

  return (
    <div className="table-container">
      <section>
        <header className="header">
          <div className="col int">Q.tà</div>
          <div className="col int">Nome</div>
          <div className="col int">Descrizione</div>
        </header>

        {storico[0].carrellos.map((item, index) => {
          let color = index % 2 > 0 ? "white" : "aliceblue";
          return (
            <div className="row" style={{ backgroundColor:color}} key={index}>
              <div className="col">{item.quantita}</div>
              <div className="col">
                {item.prodotti.categorie.nome} {item.prodotti.nome}
              </div>
              <div className="col">{item.descrizione}</div>
            </div>
          );
        })}
      </section>
    </div>
  );
};

export default Table;
