import React, { useState } from "react";
import "../style/FuoriInfo.css";

const FuoriInfo = (props) => {
  const checkList = props.ingredienti;

  const handleCheck = async (event) => {
    let ing = props.ingredienti.find(
      (p) => p.id_ingredienti == event.target.value
    );
    var updatedList = [...props.formData.ingredienti_fuori_forno];
    if (event.target.checked) {
      updatedList = [
        ...props.formData.ingredienti_fuori_forno,
        props.ingredienti.find((p) => p.id_ingredienti == event.target.value),
      ];
      props.setFormData({
        ...props.formData,
        ingredienti_fuori_forno: updatedList,
        totale: Number(props.formData.totale) + Number(ing.prezzo),
      });
    } else {
      updatedList.splice(
        props.formData.ingredienti_fuori_forno.findIndex(
          (p) => p.id_ingredienti == event.target.value
        ),
        1
      );
      props.setFormData({
        ...props.formData,
        ingredienti_fuori_forno: updatedList,
        totale: props.formData.totale - ing.prezzo,
      });
    }
  };

  const [isActive, setActive] = useState("false");

  const handleToggle = () => {
    setActive(!isActive);
  };
  return (
    <div className="container-fuorinfo">
      <div className="container-ing">
        {checkList.map((item, index) => {
          if (
            item.categorie_ingredienti.nome !==
              "Basi per Pizza Pesonalizzata" &&
            item.categorie_ingredienti.nome !==
              "Pane per Hamburger Personalizzato" &&
            item.categorie_ingredienti.nome !== "Carne per Hamburger" &&
            item.categorie_ingredienti.nome !== "Salse per Guarnizione"
          ) {
            return (
              <div className={`ingr-fuori ${isActive ? "checked" : ""}`} key={index}>
                <input
                  value={item.id_ingredienti}
                  type="checkbox"
                  onChange={handleCheck}
                  checked={
                    !props.formData.ingredienti_fuori_forno.find(
                      (p) => p.id_ingredienti == item.id_ingredienti
                    )
                      ? false
                      : true
                  }
                  onClick={handleToggle}
                />
                <span>
                  {item.nome} ({item.prezzo} €)
                </span>
              </div>
            );
          }
        })}
      </div>
      <div className="field field_v1 grande">
        <textarea
          id="first-name"
          type="text"
          className="field__input"
          placeholder="Inserisci una nota"
          onChange={(e) =>
            props.setFormData({ ...props.formData, nota: e.target.value })
          }
          value={props.formData.nota}
        />
        <span className="field__label-wrap" aria-hidden="true">
          <span className="field__label">Note</span>
        </span>
      </div>
    </div>
  );
};
export default FuoriInfo;
