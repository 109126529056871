
export const SEGNA_LOGIN = "SEGNA_LOGIN";
export const SETERROR = "SETERROR";


export const setErrore = (error) => {
  return {
    type: SETERROR,
    error,
  };
};


