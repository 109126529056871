
import { Outlet, Navigate } from "react-router-dom";



const PrivateRoutes = ({ isLogin }) => {
  return (isLogin ) ? <Outlet /> :  <Navigate to="/login" />;
};

export default PrivateRoutes;
