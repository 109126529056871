import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { node } from "../Axios";
import InputOrdine from "../components/InputOrdine";
import Spinner from "../components/Spinner";
import { attesanuovo, loading_login, logout, onClear } from "../store/actions/login";

import "../style/ApriTavolo.css";
import { getSession, removecarrello, removeSession } from "../utils/session";

function ApriTavolo({ nuovo }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading2 = useSelector((state) => state.login.loading);

  const attesa = useSelector((state) => state.login.attesanuovo);
  const [numero_tavolo, setTavolo] = useState("");
  const [numero_persone, setPersone] = useState("");

  const nuovoordine = async (email, password) => {
    await dispatch(loading_login(true));
    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };

      const bodyParameters = {
        nume_persone: numero_persone,
        numero_tavolo: numero_tavolo,
      };
      const res = await node.post(
        "/api/ordini/nuovoordinetablet",
        bodyParameters,
        config
      );

      await dispatch(attesanuovo());
      await dispatch(loading_login(false));
    } catch (error) {
      await dispatch(loading_login(false));

      if (error.response.status == "401") {
        console.log("SDFASDFASDF", error.response);
        dispatch(logout());
      }
    }
  };
 
  return (
    <div className="container-apri">
      <div className="login">
      {loading2 ? (
          <Spinner />
        ) : (
          <InputOrdine
            numero_tavolo={numero_tavolo}
            numero_persone={numero_persone}
            setTavolo={setTavolo}
            setPersone={setPersone}
            nuovoordine={nuovoordine}
          />
        )}
      </div>
    </div>
  );
}

export default ApriTavolo;
