import { useState, useEffect } from "react";

const useForm = (callback, validate, login) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors(validate(values));
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      async function fetchData() {
       const flag=await login(values.email,values.password);
       if(flag){
        callback()
       }
      }
      fetchData();
      
    }
  }, [errors]);

  return { handleChange, handleSubmit, values, errors };
};

export default useForm;
