import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";

import { logout, onClear } from "../store/actions/login";
import { inizialize } from "../store/actions/ordini";
import "../style/Logout.css";

function Logout() {
  const loading = useSelector((state) => state.login.loading);

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchlogout() {
      dispatch(logout());
    }
    fetchlogout();
  }, []);

  return <div className="login">{loading ? <Spinner /> : null}</div>;
}

export default Logout;
