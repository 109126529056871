import React, { useState } from "react";
import "../style/Modal.css";

const Modal = (props) => {
  console.log("Props in model", props);
  return (
    <>
      <div className="modal-container">
        <div className="container-infomodal">
          <div className="menu-close" onClick={() => props.toggleModal()}>
            <span class="material-symbols-outlined">close</span>
          </div>
          <div className="nome-prodotto">{props.datiModal.card.nome}</div>
          <div className="dati-prodotto">
            <div className="img-prodotto">
              <img
               src={props.datiModal.card.path}
              />
            </div>
            <div className="ingredienti-modal">
              {props.datiModal.card.ingredienti_it}
            </div>
            <div className="ingredienti-modal eng">
              {props.datiModal.card.ingredienti_en}
            </div>
          </div>
          {/* <div className="pulsanti">
            <div className="dec">
              <a
                href="#fakeLink"
                onClick={() =>
                  props.onDecrement(
                    props.datiModal.card,
                    props.datiModal.onqty,
                    props.datiModal.id_prodotto_interno,
                    props.datiModal.prezzo
                  )
                }
                className="btn purple"
              >
                -
              </a>
            </div>
            <div className="qty">{props.datiModal.onqty}</div>

            <div className="inc">
              <a
                href="#fakeLink"
                onClick={() =>
                  props.onIncrement(
                    props.datiModal.card,
                    props.datiModal.onqty,
                    props.datiModal.id_prodotto_interno,
                    props.datiModal.prezzo
                  )
                }
                className="btn purple"
              >
                +
              </a>
            </div> 
          </div>*/}
        </div>
      </div>
    </>
  );
};
export default Modal;
