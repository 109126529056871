import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "../style/InputOrdine.css";
import { getSession } from "../utils/session";

const InputOrdine = (props) => {
  return (
    <div className="page">
      <div className="field field_v1">
        <input
          id="first-name"
          type="number"
          className="field__input"
          placeholder="Inserisci il numero del tavolo"
          onChange={(e) => props.setTavolo(e.target.value)}
          value={props.numero_tavolo}
        />
        <span className="field__label-wrap" aria-hidden="true">
          <span className="field__label">Tavolo</span>
        </span>
      </div>
      <div className="field field_v2">
        <input
          id="last-name"
          type="number"
          className="field__input"
          placeholder="Inserisci il numero dei commensali"
          onChange={(e) => props.setPersone(e.target.value)}
          value={props.numero_persone}
        />
        <span className="field__label-wrap" aria-hidden="true">
          <span className="field__label">Persone</span>
        </span>
      </div>
      <a href="#fakeLink" onClick={props.nuovoordine} className="btn purple">
        Apri Tavolo
      </a>
    </div>
  );
};

export default InputOrdine;
