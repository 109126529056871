import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import "../style/Layout.css";

import Navbar from "./Navbar";
import Topbar from "./Topbar";

const Layout = (props) => {
  const togglemenu = useSelector((state) => state.ordini.togglemenu);
  return (
    <div className="container-layout">
      <div className="subcontainer-layout">
        {props.isLogin && props.nuovo ? (
          <>
            <Navbar />
          </>
        ) : null}
      </div>
      <div
        className="corpo"
        style={{
          left: togglemenu ? "0" : "220px",
        }}
      >
        <Outlet />
      </div>
    </div>
  );
};
export default Layout;
