import React, { useState } from "react";
import "../style/FornoInfo.css";

const FornoInfo = (props) => {
  const checkList = props.ingredienti;

  const handleCheck = async (event) => {
    console.log("cek",event.target.value)
    let ing = props.ingredienti.find(
      (p) => p.id_ingredienti == event.target.value
    );
    console.log("ingrediente ",ing)
    console.log("ingrediente forno prima ",props.formData.ingredienti_forno)

    var updatedList = [...props.formData.ingredienti_forno];
    if (event.target.checked) {
      updatedList = [
        ...props.formData.ingredienti_forno,
        props.ingredienti.find((p) => p.id_ingredienti == event.target.value),
      ];
      await props.setFormData({
        ...props.formData,
        ingredienti_forno: updatedList,
        totale: Number(props.formData.totale) + Number(ing.prezzo),
      });

    } else {
      updatedList.splice(
        props.formData.ingredienti_forno.findIndex(
          (p) => p.id_ingredienti == event.target.value
        ),
        1
      );
     await props.setFormData({
        ...props.formData,
        ingredienti_forno: updatedList,
        totale: props.formData.totale - ing.prezzo,
      });

    }
  };
  console.log("ingrediente forno dopo  ",props.formData.ingredienti_forno)
 



  const [isActive, setActive] = useState("false");

  const handleToggle = () => {
    setActive(!isActive);
  };
  return (
    <div className="container-fornoinfo">
      {checkList.map((item, index) => {
        if (
          item.categorie_ingredienti.nome !== "Basi per Pizza Pesonalizzata" &&
          item.categorie_ingredienti.nome !==
            "Pane per Hamburger Personalizzato" &&
          item.categorie_ingredienti.nome !== "Carne per Hamburger" &&
          item.categorie_ingredienti.nome !== "Salse per Guarnizione"
        ) {
          return (
            /* CHECKA E DECHECKA A MINKIA!!!!! */
            <div className={`ingr ${isActive ? "checked" : ""}`} key={index}>
              <input
              
                value={item.id_ingredienti}
                type="checkbox"
                onChange={handleCheck}
                checked={
                  !props.formData.ingredienti_forno.find(
                    (p) => p.id_ingredienti == item.id_ingredienti
                  )
                    ? false
                    : true
                }
              />
              {item.nome} ({item.prezzo} €)
            </div>
          );
        }
      })}
    </div>
  );
};
export default FornoInfo;
