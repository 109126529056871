import {
  CARRELLO_SUCCESS,
  CARRELLO_START,
  CARRELLO_FAIL,
  AGGIUNGI_INCREMENTA_PRODOTTO_CART,
  DECREMENTA_PRODOTTO_CART,
  ELIMINA_PRODOTTO_CART,
  RECUPERA_CARRELLO,
  SET_COPERTI,
  GET_PRODOTTI_START,
  GET_PRODOTTI_SUCCESS,
  GET_PRODOTTI_FAIL,
  SEND_ORDINE_START,
  SEND_ORDINE_SUCCESS,
  SEND_ORDINE_FAIL,
  SET_TOTALE_GENERALE,
  ISCARRELLO,
  SET_STORICO,
  GET_STORICO_ORDINi_START,
  GET_STORICO_ORDINi_SUCCESS,
  GET_STORICO_ORDINi_FAIL,
  GET_OFFERTE_START,
  GET_OFFERTE_SUCCESS,
  GET_OFFERTE_FAIL,
  GET_INGREDIENTI_START,
  GET_INGREDIENTI_SUCCESS,
  GET_INGREDIENTI_FAIL,
  SET_TAVOLO,
  SET_MENU,
} from "../actions/ordini";

const initialState = {
  prodotti: [],
  categorie: [],
  carrello: [],
  offerte: [],
  ingredienti: [],
  qty_prodotti: 0,
  tot_prezzo: 0.0,
  tot_generale: 0.0,
  coperti: 0,
  storico: 0,
  storico_ordini: [],
  tavolo: "",
  loading: false,
  iscarrello: false,
  error: false,
  togglemenu: true,
};

function ordini(state = initialState, action) {
  switch (action.type) {
    case GET_PRODOTTI_START:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODOTTI_SUCCESS:
      return {
        ...state,
        prodotti: action.res.data.prodotti[0].prodottis,
        loading: false,
        error: false,
      };
    case GET_PRODOTTI_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_OFFERTE_START:
      return {
        ...state,
        loading: true,
      };
    case GET_OFFERTE_SUCCESS:
      return {
        ...state,
        offerte: action.res.data.prodotti,
        loading: false,
        error: false,
      };
    case GET_OFFERTE_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case GET_INGREDIENTI_START:
      return {
        ...state,
        loading: true,
      };
    case GET_INGREDIENTI_SUCCESS:
      return {
        ...state,
        ingredienti: action.res.data.ingredienti,
        loading: false,
        error: false,
      };
    case GET_INGREDIENTI_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case GET_STORICO_ORDINi_START:
      return {
        ...state,
        loading: true,
      };
    case GET_STORICO_ORDINi_SUCCESS:
      return {
        ...state,
        storico_ordini: action.res.data.storico,
        loading: false,
        error: false,
      };
    case GET_STORICO_ORDINi_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case CARRELLO_START:
      return {
        ...state,
        loading: true,
      };
    case CARRELLO_SUCCESS:
      return {
        ...state,
        carrello: action.res.data.carrello,
        loading: false,
        error: false,
      };
    case CARRELLO_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SEND_ORDINE_START:
      return {
        ...state,
        loading: true,
      };
    case SEND_ORDINE_SUCCESS:
      return {
        ...state,
        carrello: [],
        qty_prodotti: 0.0,
        tot_prezzo: 0.0,
        loading: false,
        error: false,
      };
    case SEND_ORDINE_FAIL:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case SET_TOTALE_GENERALE:
      return {
        ...state,
        tot_generale: action.tot_generale,
      };

    case ELIMINA_PRODOTTO_CART:
      const prodotto1 = state.carrello.find(
        (p) => p.id_prodotto_interno === action.card.id_prodotto_interno
      );
      console.log(action.card.id_prodotto_interno);
      if (!prodotto1) {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          carrello: [...state.carrello.filter((p) => p !== prodotto1)],
          qty_prodotti: state.qty_prodotti - 1,
          tot_prezzo: state.tot_prezzo - prodotto1.prezzo,
        };
      }
    case AGGIUNGI_INCREMENTA_PRODOTTO_CART:
      let prodotto = state.carrello.find(
        (p) => p.id_prodotto === action.card.id_prodotto
      );
      const id = state.carrello.findIndex(
        (i) => i.id_prodotto === action.card.id_prodotto
      );
      console.log("index of", id);
      if (!prodotto) {
        console.log("sono qui", action.card.prezzo);
        return {
          ...state,
          carrello: [
            ...state.carrello.filter((p) => p !== prodotto),
            action.card,
          ],
          qty_prodotti: state.qty_prodotti + 1,
          tot_prezzo: state.tot_prezzo + action.card.prezzo,
        };
      } else if (prodotto.special == 0) {
        console.log("special 0");

        return {
          ...state,
          carrello: [
            ...state.carrello.filter((p) => p !== prodotto),
            { ...prodotto, quantita: prodotto.quantita + 1 },
          ],
          //carrello:[...state.carrello,prodotto],
          qty_prodotti: state.qty_prodotti + 1,
          tot_prezzo: state.tot_prezzo + prodotto.prezzo,
        };
      } else {
        if (!action.card.newspecial) {
          console.log("incremento quantiuta");
          prodotto = state.carrello.find(
            (p) => p.id_prodotto_interno === action.card.id_prodotto_interno
          );
          return {
            ...state,

            carrello: [
              ...state.carrello.filter((p) => p !== prodotto),
              { ...prodotto, quantita: prodotto.quantita + 1 },
            ],
            qty_prodotti: state.qty_prodotti + 1,
            tot_prezzo: state.tot_prezzo + prodotto.prezzo,
          };
        } else {
          prodotto = state.carrello.find(
            (p) => p.id_prodotto_interno === action.card.id_prodotto_interno
          );
          console.log("aggiungo nuovo prodotto special", prodotto);

          console.log("nonono");
          console.log("card",action.card);
          /*  const newp = state.carrello[id];
          console.log(newp); */
          if (!prodotto) {
            return {
              ...state,
              carrello: [
                ...state.carrello.filter((p) => p !== prodotto),
                action.card,
              ],
              qty_prodotti: state.qty_prodotti + 1,
              tot_prezzo: state.tot_prezzo + action.card.prezzo,
            };
          } else {
            return {
              ...state,
              carrello: [...state.carrello, action.card],
              qty_prodotti: state.qty_prodotti + 1,
              tot_prezzo: state.tot_prezzo + prodotto.prezzo,
            };
          }
        }
      }
    //altrimenti aggiungilo
    case DECREMENTA_PRODOTTO_CART:
      const prodotto2 = state.carrello.find(
        (p) => p.id_prodotto_interno === action.card.id_prodotto_interno
      );
      if (!prodotto2) {
        return {
          ...state,
        };
      } else {
        return {
          ...state,
          carrello: [
            ...state.carrello.filter((p) => p !== prodotto2),
            { ...prodotto2, quantita: prodotto2.quantita - 1 },
          ],
          qty_prodotti: state.qty_prodotti - 1,
          tot_prezzo: state.tot_prezzo - prodotto2.prezzo,
        };
      }
    case RECUPERA_CARRELLO:
      return {
        ...state,
        carrello: [...action.carrello],
        qty_prodotti: action.qty,
        tot_prezzo: action.totale,
      };

    case SET_COPERTI:
      return {
        ...state,
        coperti: action.coperti,
      };
    case ISCARRELLO:
      return {
        ...state,
        iscarrello: action.value,
      };
    case SET_STORICO:
      return {
        ...state,
        storico: action.value,
      };
    case SET_TAVOLO:
      return {
        ...state,
        tavolo: action.value,
      };
    case SET_MENU:
      return {
        ...state,
        togglemenu: action.value,
      };

    default:
      return state;
  }
}

export default ordini;
