import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import version from "../../package.json";
import Form from "../components/Form";
import { logini, getmenui } from "../store/actions/login";
import "../style/Login.css";

function Login({ isLogin, nuovo }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const id_persona = useSelector((state) => state.login.id_persona);
  const appcode = useSelector((state) => state.login.appcode);
  const navitate = useNavigate();

  const loginAcque = async (email, password) => {
    const flag = await dispatch(logini(email, password));
    if (flag) return true;

    /*   navigate("/") */
  };

  const getMenu = async (value) => {
    await dispatch(getmenui(id_persona, appcode, value));
  };

  return (
    <div className="login">
      {isLogin && nuovo ? (
        <Navigate to="/" />
      ) : isLogin && !nuovo ? (
        <Navigate to="/apritavolo" />
      ) : (
        <Form login={loginAcque} getMenu={getMenu} />
      )}
      Versione {version.version}
    </div>
  );
}

export default Login;
