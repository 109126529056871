import axios from 'axios'


const node =axios.create({
   
    //baseURL :"https://dev.gestioneacquapubblica.it/"
    //baseURL : variabili.variabili.baseURL
    baseURL :""
})

export {node}

