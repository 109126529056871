import { isContentEditable } from "@testing-library/user-event/dist/utils";
import React, { useState } from "react";
import "../style/BaseInfo.css";
import RadioButton from "./RadioButton";

const CotturaInfo = (props) => {
  let theme= props.formData.base_cottura;
  console.log(theme)

  const onChangeTheme = (e) => {
    const { id } = e.target;
    const pos = props.baseCottura.findIndex((p) => p.id_ingredienti == id);
 

    theme.forEach((element, index) => {
      console.log(index)

      if (pos === index) {
        theme[index] = true;
      } else {
        theme[index] = false;
      }
    });

    props.setFormData({
      ...props.formData,
      totale: Number(props.formData.totale)+ Number(props.baseCottura[pos].prezzo),
      base_cottura: theme,
      cottura: e.target.value,

    });



  };

  return (
    <div className="baseinfo-container">
      
      {props.baseCottura.map((item, index) => {
        

          return (
            <RadioButton
              key={index}
              id={item.id_ingredienti}
              value={item.nome}
              prezzo={item.prezzo}
              text={item.nome}
              onChange={onChangeTheme}
              checked={theme[index]}
            />
          );
        
      })}
    </div>
  );
};

export default CotturaInfo
;
