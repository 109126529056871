import React from "react";
import {Rings} from "react-loader-spinner";
import "../style/Spinner.css";

// la guida è qui https://www.npmjs.com/package/react-loader-spinner

const Spinner = () => {
  return (
    <div className="spinner">
    
      <Rings
        type="Circle"
        color="#00BFFF"
        height={100}
        width={100}
        className="container-auto m-auto justify-content-center align-item-center "
        /* timeout={3000} //3 secs */
      />
    
    </div>
  );
};

export default Spinner;
