import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Thunk from "redux-thunk";
import { createStore, applyMiddleware,compose } from "redux";
import { Provider } from "react-redux";
import rootReducer from "./store/reducers";
import * as serviceWorker from './serviceWorker';
import store from './store';
/* const middlewares = [rootReducer];
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(Thunk)) ); */
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
