import React, { useState } from "react";
import { useSelector } from "react-redux";
import "../style/Form.css";
import MySelect from "./MySelect";

const FormSuccess = ({getMenu}) => {
  const optiona = useSelector((state) => state.login.ruolo);
  return (
    <div className="container-ruoli">
      <MySelect ruoli={optiona} getMenu={getMenu}/>
    </div>
  );
};

export default FormSuccess;
