import { SETERROR } from "../actions/useform";


const initialState = {
  error: ""

};

function useform(state = initialState, action) {
  switch (action.type) {
    case SETERROR:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
}

export default useform;
