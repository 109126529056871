import { Navigate, Outlet } from "react-router-dom";


const PersistLogin = (props) => {
  console.log(props.nuovo)

  return <>{!props.nuovo ? <Outlet/>: <Navigate to="/" />}</>;
};

export default PersistLogin;
