import React from "react";
import "../style/Cards.css";

const CardMenu = (props) => {
  let prezzo = Number(props.card.prezzo);

  return (
    <div className="card">
     <a   href="#fakeLink" onClick={()=>props.toggleModal(props.card, props.onqty, props.id_prodotto_interno, props.card.prezzo)}>
        <div className="titolo">{props.card.nome}</div>
        <img
          className="image"
          src={props.card.path}

          alt="BigCo Inc. logo"
        />
        {/* <div className="ingredienti">
          <div className="it">{props.card.ingredienti_it}</div>
          <div className="en">{props.card.ingredienti_en}</div>
        </div> */}
      </a>
      {/* 
      <div className="it">{props.card.ingredienti_it}</div>
      <div className="en">{props.card.ingredienti_en}</div> */}
      <div className="conenitore-info">
      <div className="prezzo">Prezzo {Number(prezzo).toFixed(2)} €</div>

        <hr></hr>
        <div className="pulsanti">
          <div className="dec">
            <a
              href="#fakeLink"
              onClick={() =>
                props.onDecrement(
                  props.card,
                  props.onqty,
                  props.id_prodotto_interno,
                  prezzo
                )
              }
              className="btn purple"
            >
              -
            </a>
          </div>
          <div className="qty">{props.onqty}</div>

          <div className="inc">
            <a
              href="#fakeLink"
              onClick={() =>
                props.onIncrement(
                  props.card,
                  props.onqty,
                  props.id_prodotto_interno,
                  prezzo
                )
              }
              className="btn purple"
            >
              +
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardMenu;
