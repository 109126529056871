import { isContentEditable } from "@testing-library/user-event/dist/utils";
import React, { useState } from "react";
import "../style/BaseInfo.css";
import RadioButton from "./RadioButton";

const BaseInfo = (props) => {
  let theme = props.formData.base;
  console.log(theme);

  const onChangeTheme = (e) => {
    const { id } = e.target;

    const pos = props.basePizza.findIndex((p) => p.id_ingredienti == id);
console.log(pos)
    theme.forEach((element, index) => {
      if (pos === index) {
        theme[index] = true;
      } else {
        theme[index] = false;
      }
    });
    
    props.setFormData({
      ...props.formData,
      totale: props.basePizza[pos].prezzo,
      base: theme,
      descrizione_base: e.target.value,
    });
  };

  return (
    <div className="baseinfo-container">
      <div className="nome-container">
        <div className="field field_v1">
          <input
            id="first-name"
            type="text"
            className="field__input"
            placeholder="Scrivi il tuo nome"
            onChange={(e) =>
              props.setFormData({ ...props.formData, nome: e.target.value })
            }
            value={props.formData.nome}
          />
          <span className="field__label-wrap" aria-hidden="true">
            <span className="field__label">Nome</span>
          </span>
        </div>
      </div>
      {props.basePizza.map((item, index) => {
       


          return (
            <RadioButton
              key={index}
              id={item.id_ingredienti}
              value={item.nome}
              prezzo={item.prezzo}
              text={item.nome}
              onChange={onChangeTheme}
              checked={theme[index]}
            />
          );
        
      })}
    </div>
  );
};

export default BaseInfo;
