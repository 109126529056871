import React, { useState } from "react";
import "../style/Modal.css";

const Modal = (props) => {
  console.log("Props in model", props);
  return (
    <>
      <div className="modal-container">
        <div className="container-infomodal">
          <div className="menu-close" onClick={() => props.toggleModal()}>
            <span class="material-symbols-outlined">close</span>
          </div>

          <div className="testo-privacy">
            <h3>Privacy e Policy</h3>
            Politica sulla privacy La privacy dei nostri utenti è importante per
            noi. In questa politica sulla privacy descriviamo come Ristorante
            Rosa dei Venti raccoglie, utilizza, divulga e protegge le
            informazioni personali che raccogliamo attraverso il nostro sito
            web. Raccolta di informazioni Ristorante Rosa dei Venti raccoglie
            informazioni personali come il tuo nome, indirizzo email e numero di
            telefono solo quando ci vengono forniti volontariamente. Queste
            informazioni vengono utilizzate solo per inviarti offerte coupon e
            promozioni sui nostri prodotti e servizi. Non condivideremo mai
            queste informazioni con terze parti senza il tuo consenso. Utilizzo
            delle informazioni Utilizziamo le informazioni personali che
            raccogliamo solo per fornirti offerte coupon e promozioni sui nostri
            prodotti e servizi. Potremmo utilizzare le informazioni raccolte per
            contattarti via email o telefono per offrirti promozioni o
            aggiornamenti sui nostri prodotti e servizi, ma solo se hai dato il
            tuo consenso. Diritto di revoca del consenso e cancellazione dei
            dati Hai il diritto di revocare il consenso per l'uso delle tue
            informazioni personali in qualsiasi momento. Se desideri cancellare
            i tuoi dati personali, puoi farlo in qualsiasi momento cliccando sul
            link "cancellati" o "unsubscribe" situato in fondo alle email che
            riceverai da Ristorante Rosa dei Venti. Una volta ricevuta la tua
            richiesta, cancelleremo immediatamente tutte le tue informazioni
            personali dal nostro database. Tuttavia, tieni presente che potrebbe
            essere necessario mantenere alcune informazioni personali per motivi
            legali o amministrativi. Divulgazione delle informazioni Ristorante
            Rosa dei Venti non cederà mai le tue informazioni personali a terze
            parti senza il tuo consenso. Protezione delle informazioni Abbiamo
            implementato misure di sicurezza tecniche, amministrative e fisiche
            per proteggere le tue informazioni personali dalla perdita, dal
            furto, dall'abuso, dalla divulgazione non autorizzata,
            dall'alterazione e dalla distruzione. Tuttavia, non possiamo
            garantire la sicurezza delle informazioni che invii tramite
            Internet. Modifiche alla politica sulla privacy Ristorante Rosa dei
            Venti si riserva il diritto di modificare questa politica sulla
            privacy in qualsiasi momento e senza preavviso. Si prega di
            verificare periodicamente questa pagina per eventuali aggiornamenti
            o modifiche. Contattaci Se hai domande o commenti sulla nostra
            politica sulla privacy, contattaci all'indirizzo email indicato sul
            nostro sito web. Questa politica sulla privacy è stata creata da
            Ristorante Rosa dei Venti e aggiornata il 22/02/2023.
          </div>
        </div>
      </div>
    </>
  );
};
export default Modal;
