import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import "./style/App.css";

import Products from "./pages/Products";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import PrivateRoutes from "./components/PrivateRoutes";
import Layout from "./components/Layout";
import ApriTavolo from "./pages/ApriTavolo";
import NotFound from "./components/NotFound";
import Home from "./pages/Home";
import Storico from "./pages/Storico";
import Offerte from "./pages/Offerte";
import { useDispatch } from "react-redux";
import Pusher from "pusher-js";
import { useEffect, useState } from "react";
import {
  ATTESANUOVO,
  attesanuovo,
  get_categorie,
  LOGIN_CHECK,
  logout,
  loading_login,
  onClear,
} from "./store/actions/login";
import PersistLogin from "./components/PersistLogin";
import PersistLogin2 from "./components/PersistLogin2";
import {
  aggiungi_incrementa_prodotto_cart,
  recuperacarqr,
  recupera_carrello,
  set_coperti,
  set_storico_ordine_aperto,
  set_totale_generale,
} from "./store/actions/ordini";
import {
  getSession,
  inizializzastorage,
  setAttesaNuovo,
  setCarrello,
} from "./utils/session";
import { Navigate } from "react-router-dom";
import { node } from "./Axios";
import Carrello from "./pages/Carrello";
import Spinner from "./components/Spinner";
import PizzaStepper from "./pages/PizzaStepper";
import HamburgerStepper from "./pages/HamburgerStepper";
import PiadinaStepper from "./pages/PiadinaStepper";

//TODO creare store per gestire tutti gli errori
//TODO implementare funzione che restituisce prezzo totale del carrello poichè il logout farebbe perbere il totale
function App() {
  const dispatch = useDispatch();
  const carrello = useSelector((state) => state.ordini.carrello);
  const [controllocart, setControlloCart] = useState(false);
  const isLogin = useSelector((state) => state.login.isLogin);
  const loading = useSelector((state) => state.login.loading);
  const nuovo = useSelector((state) => state.login.attesanuovo);

  useEffect(() => {
    console.log("sòodifa");
    if (controllocart) setCarrello(carrello);
  });
  useEffect(() => {
    Pusher.logToConsole = true;

    var pusher = new Pusher("335857782fa9261e9191", {
      cluster: "eu",
    });

    var channel = pusher.subscribe("message");
    channel.bind("logoutmassivo", function (data) {
      async function logoutmassivo() {
        await dispatch(logout());
      }

      logoutmassivo();
    });

    channel.bind("chiudiordine", function (data) {
      async function fetchattesanuoo() {
        const sessione = await getSession();
        console.log(sessione);
        console.log(JSON.stringify(data));
        if (sessione.id == JSON.stringify(data.message)) {
          await dispatch(loading_login(true));

          await dispatch(attesanuovo());
          await dispatch(loading_login(false));
        }
        //TODO azzerare carrello e quant altro
      }

      fetchattesanuoo();
    });

    channel.bind("aggiornatitablettavolo", function (data) {
      async function fetchrecuperacarrello_tmp() {
        const sessione = await getSession();

        if (sessione.id == JSON.stringify(data.message)) {
          await dispatch(loading_login(true));

          try {
            const sessione = await getSession();
            const config = {
              headers: { Authorization: `Bearer ${sessione.token}` },
            };
            const bodyParameters = {};
            const res = await node.post(
              "/api/ordini/recuperacarqr",
              bodyParameters,
              config
            );
            console.log(res);
            for (const card of res.data.ordineaperto[0].carrello_tmps) {
              for (let i = 1; i <= card.quantita; i++) {
                let p = {
                  id_prodotto: card.prodottiIdProdotto,
                  nome: card.nome,
                  ingredienti_it: card.ingredienti_it,
                  ingredienti_en: card.ingredienti_en,
  
                  prezzo: card.prezzo,
                  special: card.special,
                  newspecial: card.newspecial,
                  quantita: 1,
                  id_prodotto_interno: card.id_prodotto_interno,
                };
                await dispatch(aggiungi_incrementa_prodotto_cart(p));
                await setControlloCart(true);
              }
             
              
              
            }
          } catch (error) {}

          await dispatch(loading_login(false));
        }
        //TODO azzerare carrello e quant altro
      }

      fetchrecuperacarrello_tmp();
    });
    channel.bind("aggiornatidaticarrello", function (data) {
      async function fetchaggiornadaticarrello() {
        await dispatch(attesanuovo());
        console.log("aggiorno dati in carrello", nuovo);
      }

      fetchaggiornadaticarrello();
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);
  useEffect(() => {
    async function fetch_get_categorie() {
      await dispatch(get_categorie());
    }
    fetch_get_categorie();
  }, []);
  useEffect(() => {
    async function fetch_recupera_carrello_locale() {
      try {
        let items = JSON.parse(localStorage.getItem("CARRELLO"));
        if (items && items.length > 0) {
          let qty = 0;
          let totale = 0.0;
          items.forEach((item, index) => {
            qty = qty + item.quantita;
            totale = totale + item.quantita * item.prezzo;
          });

          await dispatch(recupera_carrello(items, qty, totale));
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetch_recupera_carrello_locale();
  }, []);
  useEffect(() => {
    /*  async function fetchlogin() {
      await dispatch(attesanuovo())
    }
    fetchlogin() */
    async function fetchlogin() {
      const sessione = await getSession();

      if (!sessione) {
        return;
      }

      await dispatch({ type: LOGIN_CHECK, sessione });

      try {
        const sessione = await getSession();
        const config = {
          headers: { Authorization: `Bearer ${sessione.token}` },
        };

        const bodyParameters = {};
        const res = await node.post(
          "/api/login/attesanuovo",
          bodyParameters,
          config
        );

        if (res.data.tavoloaperto.length > 0) {
          await dispatch({ type: ATTESANUOVO, attesanuovo: true });
          await dispatch(
            set_coperti(res.data.tavoloaperto[0].ordini_tavolos[0].nume_persone)
          );
          await dispatch(set_totale_generale(res.data.tavoloaperto[0].totale));
          await dispatch(
            set_storico_ordine_aperto(res.data.tavoloaperto[0].storico)
          );
          await setAttesaNuovo(true);
          console.log("attesa nuovo nel action : true");
        } else {
          await dispatch({ type: ATTESANUOVO, attesanuovo: false });
          await setAttesaNuovo(false);
          console.log("attesa nuovo nel action: false");
        }
      } catch (error) {
        if (error.response.status == "401") {
          dispatch(logout());
        }
      }
    }

    fetchlogin();
  }, []);

  return (
    <div className="App">
      {loading ? (
        <Spinner />
      ) : (
        <Router>
          <Routes>
            {
              <Route
                path="/"
                element={<Layout isLogin={isLogin} nuovo={nuovo} />}
              >
                <Route
                  path="login"
                  element={<Login isLogin={isLogin} nuovo={nuovo} exact />}
                />
                <Route path="*" element={<NotFound />} />
                <Route element={<PrivateRoutes isLogin={isLogin} />}>
                  <Route element={<Logout />} path="/logout" />
                  <Route element={<PersistLogin nuovo={nuovo} />}>
                    <Route
                      element={<ApriTavolo nuovo={nuovo} />}
                      path="/apritavolo"
                    />
                  </Route>
                  <Route element={<PersistLogin2 nuovo={nuovo} />}>
                    <Route element={<Home />} path="/" exact />
                    <Route element={<Storico />} path="/storico" exact />
                    <Route element={<Offerte />} path="/offerte" exact />
                    <Route
                      element={<PizzaStepper />}
                      path="/pizzastepper/:id/:uui"
                      exact
                    />
                    <Route
                      element={<PiadinaStepper />}
                      path="/piadinastepper/:id/:uui"
                      exact
                    />
                    <Route
                      element={<HamburgerStepper />}
                      path="/hamburgerstepper/:id/:uui"
                      exact
                    />
                    <Route element={<Products />} path="/products/:id/:flag" />
                    <Route element={<Carrello />} path="/carrello" />
                  </Route>
                </Route>
                <Route path="*" element={<NotFound />} />
              </Route>
            }
          </Routes>
        </Router>
      )}
    </div>
  );
}

export default App;
