import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import "../style/Navbar.css";
import logo from "../assets/logo_menu_manager.png";
import { togglenav } from "../store/actions/ordini";

const Navbar = () => {
  const dispatch=useDispatch();
  const categorie = useSelector((state) => state.login.categorie);
  const menu = useSelector((state) => state.login.menu);
  const togglemenu = useSelector((state) => state.ordini.togglemenu);
  const toggle = () => {
    dispatch(togglenav(!togglemenu))
  };
  return (
    <nav
      className="nav"
      style={{
        left: togglemenu ? "-221px" : "0",
      }}
    >
      <div className="menu_icon_container">
        <img src={logo}></img>
        <div className="menu_icon menu_open" onClick={toggle}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div className="content-nav">
        {menu.map((voce, index) => (
          <NavLink
            to={`${voce.nome}/`}
            className="line"
            style={({ isActive }) => ({
              color: isActive ? "#fff" : "#545e6f",
              background: isActive ? "#105c82" : null,
            })}
            key={index}

          onClick={toggle}
          >
            {voce.alias}
          </NavLink>
        ))}
        <NavLink
          to={`offerte`}
          className="line"
          style={({ isActive }) => ({
            color: isActive ? "#fff" : "#545e6f",
            background: isActive ? "#105c82" : null,
          })}
          onClick={toggle}
        >
          Offerte
        </NavLink>

        {categorie.map((cat, index) => (
          <NavLink
            to={`products/${cat.id_categoria}/${cat.tipo}`}
            className="line"
            key={index}
            style={({ isActive }) => ({
              color: isActive ? "#fff" : "#545e6f",
              background: isActive ? "#105c82" : null,
            })}
            onClick={toggle}
          >
            {cat.nome}
          </NavLink>
        ))}
      </div>
      {/* <NavLink to="/logout" className="logout active">
        Logout
      </NavLink> */}
    </nav>
  );
};
export default Navbar;
