import { node } from "../../Axios";
import { getSession, removecarrello, setCarrello } from "../../utils/session";
import { logout, onClear } from "./login";

export const CARRELLO_START = "CARRELLO_START";
export const CARRELLO_SUCCESS = "CARRELLO_SUCCESS";
export const CARRELLO_FAIL = "CARRELLO_FAIL";

/* export const INSERT_CAR_START = "INSERT_CAR_START";
export const NSERT_CAR_SUCCESS = "NSERT_CAR_SUCCESS";
export const NSERT_CAR_FAIL = "NSERT_CAR_FAIL"; */
export const ELIMINA_PRODOTTO_CART = "ELIMINA_PRODOTTO_CART";
export const AGGIUNGI_INCREMENTA_PRODOTTO_CART =
  "AGGIUNGI_INCREMENTA_PRODOTTO_CART";
export const DECREMENTA_PRODOTTO_CART = "DECREMENTA_PRODOTTO_CART";
export const RECUPERA_CARRELLO = "RECUPERA_CARRELLO";

export const INIZIALIZE = "INIZIALIZE";
export const SET_COPERTI = "SET_COPERTI";

export const GET_PRODOTTI_START = "GET_PRODOTTI_START";
export const GET_PRODOTTI_SUCCESS = "GET_PRODOTTI_SUCCESS";
export const GET_PRODOTTI_FAIL = "GET_PRODOTTI_FAIL";

export const GET_INGREDIENTI_START = "GET_INGREDIENTI_START";
export const GET_INGREDIENTI_SUCCESS = "GET_INGREDIENTI_SUCCESS";
export const GET_INGREDIENTI_FAIL = "GET_INGREDIENTI_FAIL";

export const GET_OFFERTE_START = "GET_OFFERTE_START";
export const GET_OFFERTE_SUCCESS = "GET_OFFERTE_SUCCESS";
export const GET_OFFERTE_FAIL = "GET_OFFERTE_FAIL";

export const SEND_ORDINE_START = "SEND_ORDINE_START";
export const SEND_ORDINE_SUCCESS = "SEND_ORDINE_SUCCESS";
export const SEND_ORDINE_FAIL = "SEND_ORDINE_FAIL";

export const SET_TOTALE_GENERALE = "SET_TOTALE_GENERALE";
export const ISCARRELLO = "ISCARRELLO";
export const SET_STORICO = "SET_STORICO";

export const GET_STORICO_ORDINi_START = "GET_STORICO_ORDINi_START";
export const GET_STORICO_ORDINi_SUCCESS = "GET_STORICO_ORDINi_SUCCESS";
export const GET_STORICO_ORDINi_FAIL = "GET_STORICO_ORDINi_FAIL";

export const SET_TAVOLO = "SET_TAVOLO";
export const SET_MENU = "SET_MENU";

export const send_ordine = (carrello, totale) => {
  return async (dispatch) => {
    await dispatch(send_ordine_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {
        carrello: carrello,
        totale: totale.toFixed(2),
      };
      const res = await node.post(
        "/api/ordini/inviaordine",
        bodyParameters,
        config
      );
      await removecarrello();
      console.log(res);
      await dispatch(set_totale_generale(res.data.oldtotale + totale));

      await dispatch(send_ordine_success(res));
      await dispatch(set_storico_ordine_aperto(1));
    } catch (error) {
      console.log(error);
      await dispatch(send_ordine_fail());
    }
  };
};
export const send_ordine_start = () => {
  return {
    type: SEND_ORDINE_START,
  };
};
export const send_ordine_success = (res) => {
  return {
    type: SEND_ORDINE_SUCCESS,
    res,
  };
};
export const send_ordine_fail = () => {
  return {
    type: SEND_ORDINE_FAIL,
  };
};

export const getcarrello = () => {
  return async (dispatch) => {
    await dispatch(getcarrello_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/ordini/getcarrello",
        bodyParameters,
        config
      );

      await dispatch(getcarrello_success(res));
    } catch (error) {
      await dispatch(get_carrello_fail());
    }
  };
};
export const getcarrello_start = () => {
  return {
    type: CARRELLO_START,
  };
};
export const getcarrello_success = (res) => {
  return {
    type: CARRELLO_SUCCESS,
    res,
  };
};
export const get_carrello_fail = () => {
  return {
    type: CARRELLO_FAIL,
  };
};
export const get_prodotti = (id) => {
  return async (dispatch) => {
    await dispatch(get_prodotti_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {
        id_categoria: id,
      };
      const res = await node.post(
        "/api/prodotti/getprodottibycategoria",
        bodyParameters,
        config
      );

      await dispatch(get_prodotti_success(res));
    } catch (error) {
      await dispatch(get_prodotti_fail());
      await dispatch(logout());
    }
  };
};

export const recuperacarqr = () => {
  
  return async (dispatch) => {
    // await dispatch(get_prodotti_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/ordini/recuperacarqr",
        bodyParameters,
        config
      );
      console.log(res);
      for (const card of res.data.ordineaperto[0].carrello_tmps) {
      let p = {
        id_prodotto: card.prodotti_id_prodotto,
        nome: card.nome,
        ingredienti_it: card.ingredienti_it,
        ingredienti_en: card.ingredienti_en,
        
        prezzo: card.prezzo,
        special: 0,
        newspecial: false,
        quantita: card.quantita,
        id_prodotto_interno: card.id_prodotto_interno,
      };
    // dispatch(aggiungi_incrementa_prodotto_cart(p));
    // setCarrello(carrello)
   /*  console.log("carrello",carrello) */
    /* console.log(p) */
    } 
    
      // await dispatch(get_prodotti_success(res));
    } catch (error) {
      // await dispatch(get_prodotti_fail());
      //  await dispatch(logout())
    }
  };
};

export const get_prodotti_start = () => {
  return {
    type: GET_PRODOTTI_START,
  };
};
export const get_prodotti_success = (res) => {
  return {
    type: GET_PRODOTTI_SUCCESS,
    res,
  };
};
export const get_prodotti_fail = () => {
  return {
    type: GET_PRODOTTI_FAIL,
  };
};

export const get_offerte = (id) => {
  return async (dispatch) => {
    await dispatch(get_offerte_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {
        id_categoria: id,
      };
      const res = await node.post(
        "/api/prodotti/getofferte",
        bodyParameters,
        config
      );

      await dispatch(get_offerte_success(res));
    } catch (error) {
      await dispatch(get_offerte_fail());
      await dispatch(logout());
    }
  };
};
export const get_offerte_start = () => {
  return {
    type: GET_OFFERTE_START,
  };
};
export const get_offerte_success = (res) => {
  return {
    type: GET_OFFERTE_SUCCESS,
    res,
  };
};
export const get_offerte_fail = () => {
  return {
    type: GET_OFFERTE_FAIL,
  };
};

export const get_storico_ordini = (/* id */) => {
  return async (dispatch) => {
    await dispatch(get_storico_ordini_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {
        /* id_categoria: id, */
      };
      const res = await node.post(
        "/api/ordini/getstoricoordine",
        bodyParameters,
        config
      );

      await dispatch(get_storico_ordini_success(res));
    } catch (error) {
      await dispatch(get_prodotti_fail());
      await dispatch(logout());
    }
  };
};

export const get_storico_ordini_start = () => {
  return {
    type: GET_STORICO_ORDINi_START,
  };
};
export const get_storico_ordini_success = (res) => {
  return {
    type: GET_STORICO_ORDINi_SUCCESS,
    res,
  };
};
export const get_storico_ordini_fail = () => {
  return {
    type: GET_STORICO_ORDINi_FAIL,
  };
};

export const get_ingredienti_per_pizza_personalizzata = (id) => {
  return async (dispatch) => {
    await dispatch(get_ingredienti_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/ingredienti/getingredientiperpezzapersonalizzata",
        bodyParameters,
        config
      );
      console.log(res)
      await dispatch(get_ingredienti_success(res));
    } catch (error) {
      await dispatch(get_ingredienti_fail());
      // await dispatch(logout());
    }
  };
};
export const get_ingredienti_per_piadina_personalizzata = (id) => {
  return async (dispatch) => {
    await dispatch(get_ingredienti_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/ingredienti/getingredientiperpiadinapersonalizzata",
        bodyParameters,
        config
      );
      await dispatch(get_ingredienti_success(res));
    } catch (error) {
      await dispatch(get_ingredienti_fail());
      // await dispatch(logout());
    }
  };
};
export const get_ingredienti_per_hamburger_personalizzata = (id) => {
  return async (dispatch) => {
    await dispatch(get_ingredienti_start());

    try {
      const sessione = await getSession();
      const config = {
        headers: { Authorization: `Bearer ${sessione.token}` },
      };
      const bodyParameters = {};
      const res = await node.post(
        "/api/ingredienti/getingredientiperhamburgerpersonalizzata",
        bodyParameters,
        config
      );
      await dispatch(get_ingredienti_success(res));
    } catch (error) {
      await dispatch(get_ingredienti_fail());
      // await dispatch(logout());
    }
  };
};

export const get_ingredienti_start = () => {
  return {
    type: GET_INGREDIENTI_START,
  };
};
export const get_ingredienti_success = (res) => {
  return {
    type: GET_INGREDIENTI_SUCCESS,
    res,
  };
};
export const get_ingredienti_fail = () => {
  return {
    type: GET_INGREDIENTI_FAIL,
  };
};
export const inizialize = () => {
  return {
    type: INIZIALIZE,
  };
};
export const elimina_prodotto_cart = (card) => {
  return {
    type: ELIMINA_PRODOTTO_CART,
    card,
  };
};

export const decrementa_prodotto_cart = (card) => {
  return {
    type: DECREMENTA_PRODOTTO_CART,
    card,
  };
};
export const aggiungi_incrementa_prodotto_cart = (card) => {
  return {
    type: AGGIUNGI_INCREMENTA_PRODOTTO_CART,
    card,
  };
};

export const recupera_carrello = (carrello, qty, totale) => {
  return {
    type: RECUPERA_CARRELLO,
    carrello,
    qty,
    totale,
  };
};
export const set_coperti = (coperti) => {
  return {
    type: SET_COPERTI,
    coperti,
  };
};
export const set_totale_generale = (tot_generale) => {
  return {
    type: SET_TOTALE_GENERALE,
    tot_generale,
  };
};
export const set_iscarrello = (value) => {
  return {
    type: ISCARRELLO,
    value,
  };
};
export const set_storico_ordine_aperto = (value) => {
  return {
    type: SET_STORICO,
    value,
  };
};
export const set_tavolo = (value) => {
  return {
    type: SET_TAVOLO,
    value,
  };
};
export const togglenav = (value) => {
  return {
    type: SET_MENU,
    value,
  };
};
